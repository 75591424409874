import { __decorate } from "tslib";
import { Component, Prop, Ref, Vue, Watch, } from 'vue-property-decorator';
import { LabelService } from '@/sevices';
import { SourceType, TagType } from '../../../typings/organization/label-manage';
import { NodeType, UnitType } from '../../../typings/organization/org-manage';
import axios from 'axios';
import { StaffSelector, CTable } from '@cloudpivot-hermes/common-components';
import * as Utils from '@/common/utils';
import Perm from '@/router/permission';
const CancelToken = axios.CancelToken;
let cancel = null;
let LabelInfo = class LabelInfo extends Vue {
    constructor() {
        super(...arguments);
        this.SourceType = SourceType;
        this.UnitType = UnitType;
        this.addUserLoading = false;
        this.setScopeLoading = false;
        this.userScope = [];
        this.tableColumns = [
            {
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                fixed: 'left',
                slots: { title: 'indexTitle', name: '序号' },
                scopedSlots: { customRender: 'index' },
            },
            {
                width: 300,
                dataIndex: 'unitName',
                key: 'unitName',
                ellipsis: true,
                scopedSlots: { customRender: 'unitName' },
                slots: { title: 'unitNameTitle', name: '名称' },
            },
            {
                width: 300,
                dataIndex: 'departmentFullPath',
                key: 'departmentFullPath',
                ellipsis: true,
                slots: { title: 'departmentFullPathTitle', name: '所属部门' },
                scopedSlots: { customRender: 'departmentFullPath' },
            },
            {
                dataIndex: 'manageField',
                key: 'manageField',
                ellipsis: true,
                slots: { title: 'manageFieldTitle', name: '管理范围' },
            },
            {
                width: 80,
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                fixed: 'right',
                slots: { title: 'actionTitle', name: '操作' },
                scopedSlots: { customRender: 'action' },
            },
        ];
        this.searchParams = {};
        this.selectionRow = [];
        this.userCount = 0;
        this.orgId = '';
        this.setScopeUser = null;
        this.isHasTableList = false;
    }
    onKeywordChange() {
        this.searchParams.name = this.searchParams.name?.replace(/^\s*|\s*$/g, '');
    }
    get enableCheckbox() {
        return this.labelDetail.sourceType === SourceType.SelfMaintain && this.isHasTableList;
    }
    get hasUserTablePerm() {
        const permCode = this.pageType === 'common'
            ? this.$PermCode.organization.orgTagManageCommonUserDept
            : this.$PermCode.organization.orgTagManageOrganizationUserDept;
        return Perm.hasCodePerm(permCode);
    }
    async setDynamicTableHeight() {
        if (this.labelDetail.isEdit)
            return;
        await this.$nextTick();
        this.getLabelDetail();
        const ctable = await Utils.getComponentByRefs(this.$refs, 'ctable');
        if (ctable) {
            ctable.setScrollHeight();
            this.searchParams = {
                labelType: TagType.Label,
                id: this.labelDetail.id,
                name: '',
            };
            ctable.getTableList();
        }
    }
    /**
       * 搜索
       */
    async search(keyword) {
        this.searchParams = {
            labelType: TagType.Label,
            id: this.labelDetail.id,
            name: keyword,
        };
        this.ctable.pagination.current = 1;
        this.ctable.pagination.pageSize = 20;
        return this.ctable.getTableList();
    }
    /**
       * 点击删除部门/用户
       */
    async deleteItems() {
        const labelUserIds = [];
        this.selectionRow.forEach(item => {
            labelUserIds.push(item.id);
        });
        this.$confirm({
            title: '确定删除该部门/用户吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: async () => {
                const res = await LabelService.delDeptAndStaff(labelUserIds);
                if (!res.success) {
                    this.$message.error(res.errMessage);
                }
                this.$message.success('删除成功');
                this.search('');
                this.getLabelDetail();
            },
        });
    }
    /**
       * 显示添加人员弹窗
       */
    addUser() {
        this.staffSelector.showModal();
    }
    addUserFromStaffSelector(staffs) {
        if (!staffs.length) {
            this.$message.warning('请选择至少一个人员/部门');
            return;
        }
        const users = staffs.map((item) => {
            return {
                unitId: item.id,
                type: item.type,
            };
        }) || [];
        this.addUserLoading = true;
        const params = { data: users, labelId: this.labelDetail.id };
        LabelService.addUser(params).then((res) => {
            if (res.success) {
                // 更新标签详情
                return this.getLabelDetail();
            }
        }).then(() => {
            return this.search('');
        }).then((success) => {
            if (success) {
                this.staffSelector.closeModal();
            }
        }).finally(() => {
            this.addUserLoading = false;
        });
    }
    getLabelDetail() {
        if (typeof cancel === 'function') {
            cancel();
        }
        return LabelService.getLabelOrGroupDetail(this.labelDetail.id, {
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
        }).then((res) => {
            if (res.success) {
                this.userCount = res.data.userCount;
                this.orgId = res.data.orgId;
            }
        }).finally(() => {
            cancel = null;
        });
    }
    changeTable() {
        if (this.ctable) {
            this.isHasTableList = !!this.ctable.getTableData().length;
            this.selectionRow = this.ctable.getSelectionRow();
        }
    }
    async setScope(record) {
        const tableData = this.ctable.getTableData();
        tableData.forEach((item) => {
            item.loading = false;
        });
        this.userScope = record.scopes?.map((item) => {
            return {
                id: item.unitId,
                name: item.unitName,
                type: item.type,
            };
        }) || [];
        record.loading = true;
        await this.scopeStaffSelector.showModal();
        this.setScopeUser = record;
        record.loading = false;
    }
    cancelScope() {
        this.userScope = [];
    }
    setScopeFromStaffSelector(staffs) {
        const users = staffs.map((item) => {
            return {
                unitId: item.id,
                type: item.type,
            };
        }) || [];
        this.setScopeLoading = true;
        const params = { data: users, labelUserId: this.setScopeUser.id };
        LabelService.setScope(params).then((res) => {
            if (res.success) {
                return this.search('');
            }
        }).then((success) => {
            if (success) {
                this.scopeStaffSelector.closeModal();
            }
        }).finally(() => {
            this.setScopeLoading = false;
        });
    }
    /**
       * 获取列表数据接口
       */
    async getList(params, options) {
        const p = {
            ...params,
            ...this.searchParams,
            page: params.page,
        };
        return new Promise((resolve) => {
            LabelService.getTableListById(p, options).then((res) => {
                if (res.success && Array.isArray(res.data)) {
                    res.data.forEach((item) => {
                        if (item.type === 'DEPARTMENT') {
                            item.nodeType = NodeType.Dept;
                            item.type = UnitType.Dept;
                        }
                        else if (item.type === 'USER') {
                            item.nodeType = NodeType.User;
                            item.type = UnitType.User;
                        }
                        item.loading = false;
                        item.scopes = item.scopes?.map((scope) => {
                            return {
                                ...scope,
                                type: scope.type === 'DEPARTMENT' ? UnitType.Dept : UnitType.User,
                            };
                        });
                        item.manageField = item.scopes?.map(scope => scope.unitName).join(',') || '--';
                    });
                    resolve(res);
                }
            });
        });
    }
    // 添加部门/人员
    get staffSelectorParams() {
        return {
            orgId: this.orgId,
            resourceCode: 'org-tag-manage-organization-manage',
        };
    }
};
__decorate([
    Ref()
], LabelInfo.prototype, "ctable", void 0);
__decorate([
    Ref()
], LabelInfo.prototype, "labelDetailH", void 0);
__decorate([
    Ref()
], LabelInfo.prototype, "labelBtn", void 0);
__decorate([
    Ref()
], LabelInfo.prototype, "labelTable", void 0);
__decorate([
    Ref()
], LabelInfo.prototype, "staffSelector", void 0);
__decorate([
    Ref()
], LabelInfo.prototype, "scopeStaffSelector", void 0);
__decorate([
    Prop({ default: () => { } })
], LabelInfo.prototype, "labelDetail", void 0);
__decorate([
    Prop()
], LabelInfo.prototype, "pageType", void 0);
__decorate([
    Watch('searchParams.name')
], LabelInfo.prototype, "onKeywordChange", null);
LabelInfo = __decorate([
    Component({
        name: 'LabelInfo',
        components: {
            CTable,
            TableDropDownPath: () => import('@/components/organization/org-manage/table-dropdown-path.vue'),
            Avatar: () => import('@/components/organization/org-manage/avatar.vue'),
            NoPermission: () => import('@/components/global/no-permission.vue'),
            StaffSelector,
        },
    })
], LabelInfo);
export default LabelInfo;
